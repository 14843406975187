var $j = jQuery.noConflict();

// Define App Namespace
var app = {
    
	// Initializer
	init: function() {
        app.heroCarousel();
        app.productCarousel();
        app.productTabs();
        app.productViewCarousel();
	},

    heroCarousel : function() {
    	$j('.hero-carousel-inner').slick({
    		arrows: true,
    		dots: true
    	});
    },
    
    productCarousel : function() {
        $j('.product-tab-inner').slick({
            autoplay: true,
            autoplaySpeed: 7000,
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 1
        });
    },
    
    productTabs : function() {

        $j('#product-tabs li a:not(:first)').addClass('inactive');
        $j('.product-tab-content').removeClass('visible');
        $j('.product-tab-content:first').addClass('visible');

        $j('#product-tabs li a').click(function(){
            
            var tabContent = $j(this).attr('id');
            
            if($j(this).hasClass('inactive')){
                
                $j('#product-tabs li a').addClass('inactive');
                $j(this).removeClass('inactive');

                $j('.product-tab-content').removeClass('visible');
                $j('#'+ tabContent + 'C').addClass('visible');
                
            }
        });
    },
    
    productViewCarousel : function() {
        $j(".js--thumb__anchor").click(function(e){
            var href = $j(this).attr("href");
            $j(".js--product_block__image").attr("src", href);
            e.preventDefault();
            return false;
        });

        setTimeout(function(){ 
            $j('.js--thumb').slick({
                lazyLoad: 'ondemand',
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                speed: 300,
                draggable: false,
                touchMove: false,
                vertical: true,
                responsive: [
                    {   
                        breakpoint: 500,
                        settings: {
                            vertical: false,
                            refresh: true,
                            draggable: true
                        }
                    }
                ]
            }); 
        }, 100);
    }
};
/* Initialize App */
jQuery(function($){app.init();});